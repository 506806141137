import React from 'react';
import './male_female.css';

const MaleFemale = () => {
  return (
    <div className="male-female-container">
      <div className="card male-card">
        <img
          src="./img/male.png" // Replace with the correct path to male image
          alt="Male Product"
          className="product-image"
        />
        <h3 className="card-title">पुरुषों के लिए</h3>
        <ul className="card-list">
          <li>शुक्राणुओं का ना बनना या फिर कम बनना</li>
          <li>हार्मोनल प्रॉब्लम का होना</li>
          <li>शुक्राणुओं का कम motile होना</li>
          <li>वीर्य की पतलापन होना</li>
          <li>
            टेस्टिस में इंफेक्शन यह सब बांझपन की समस्या के मुख्य कारण हैं, जो पुरुषों में अधिक पाई जाती हैं।
          </li>
        </ul>
        <p className="card-description">
          Shishu Vardan दवा इन सभी समस्याओं के लिए एक रामबाण इलाज है।
        </p>
      </div>
      <div className="card female-card">
        <img
          src="./img/female.png" // Replace with the correct path to female image
          alt="Female Product"
          className="product-image"
        />
        <h3 className="card-title">महिलाओं के लिए</h3>
        <ul className="card-list">
          <li>अंडों का कम बनना या फिर समय पर नहीं बनना या फूटना</li>
          <li>अंडेदानी में सिस्टों का होना</li>
          <li>गर्भ का बार-बार गिरना या ना ठहरना</li>
          <li>PCOD/PCOS (अंडेदानी में पानी वाली सिस्टों का होना)</li>
          <li>हार्मोनल प्रॉब्लम का होना</li>
          <li>
            मासिक धर्म समय पर नहीं आना या फिर बिल्कुल ही नहीं आना
          </li>
        </ul>
        <p className="card-description">
          Shishu Vardan दवा इन सभी समस्याओं का एक प्रभावी समाधान है।
        </p>
      </div>
    </div>
  );
};

export default MaleFemale;
