import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './Info.css';

const Info = () => {

  // Scroll to top when the page is loaded
  useEffect(() => {
    window.scrollTo(0, 0); // Force scroll to top
    // Alternatively, use smooth scroll for better experience
    // window.scroll({ top: 0, behavior: 'smooth' });
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <>
      <Helmet>
        <title>दवा की जानकारी</title> {/* Title for the page */}
        <meta name="description" content="महिलाओं और पुरुषों के लिए आयुर्वेदिक कैप्सूल की जानकारी, गर्भधारण से संबंधित महत्वपूर्ण टिप्स और मनी बैक गारंटी के बारे में।" />
        {/* Add any additional meta tags as needed */}
      </Helmet>

      <div className="info-container">
        {/* Women's Product Card */}
        <div className="product-card">
          <h2 className="product-title">महिलाओं के लिए आयुर्वेदिक कैप्सूल</h2>
          <p className="product-description">
            महिलाओं को रोज़ सुबह नाश्ता करने के बाद गरम पानी या दूध के साथ यह आयुर्वेदिक कैप्सूल लेना चाहिए। 
            यह दिन में सिर्फ एक बार लेना है, सुबह नाश्ता करने के बाद। 
            इस कैप्सूल का नियमित सेवन महिला की संतान क्षमता को बढ़ाता है और प्रजनन स्वास्थ्य को बेहतर बनाता है।
          </p>
          <img src="./img/female.png" alt="महिलाओं के लिए बोतल" className="product-image" />
          <p className="product-note">यह बोतल महिलाओं के लिए है।</p>
        </div>

        {/* Men's Product Card */}
        <div className="product-card">
          <h2 className="product-title">पुरुषों के लिए आयुर्वेदिक कैप्सूल</h2>
          <p className="product-description">
            पुरुषों को रोज़ सुबह नाश्ता करने के बाद गरम पानी या दूध के साथ यह आयुर्वेदिक कैप्सूल लेना चाहिए। 
            यह दिन में सिर्फ एक बार लेना है, सुबह नाश्ता करने के बाद। 
            इस कैप्सूल का नियमित सेवन पुरुष की संतान क्षमता को बढ़ाता है और प्रजनन स्वास्थ्य को बेहतर बनाता है।
          </p>
          <img src="./img/male.png" alt="पुरुषों के लिए बोतल" className="product-image" />
          <p className="product-note">यह बोतल पुरुषों के लिए है।</p>
        </div>

        {/* Additional Information Section */}
        <div className="additional-info">
          <h3>बच्चे के लिए प्रयास करने के सही समय के बारे में:</h3>
          <p>
            महिलाओं के लिए, माहवारी के पहले दिन से लेकर अगले चक्र तक के बीच 12 से 18 दिनों के अंदर गर्भधारण की सबसे अधिक संभावना होती है। यह समय "ओव्यूलेशन" के समय के आस-पास होता है, जब अंडाणु ओवरी से निकलता है और गर्भाधान के लिए तैयार होता है।
          </p>
          <p>
            ओव्यूलेशन का समय महिला के सायकल की लंबाई पर निर्भर करता है, लेकिन सामान्यत: यह माहवारी के पहले दिन से 14 दिनों बाद होता है। 
            यदि आपके सायकल का समय 28 दिन का है, तो ओव्यूलेशन लगभग 14वें दिन होता है। आप ओव्यूलेशन ट्रैकिंग ऐप्स का इस्तेमाल करके इसे और सटीकता से ट्रैक कर सकती हैं।
          </p>
          <h3>क्या खाना चाहिए:</h3>
          <ul>
            <li>स्वस्थ आहार का सेवन करें: घर का बना खाना और ताजे फल-सब्जियाँ खाएं।</li>
            <li>प्राकृतिक प्रोटीन, जैसे दाल, सोयाबीन, दूध और नट्स का सेवन करें।</li>
            <li>पानी और ताजे जूस का सेवन ज्यादा करें।</li>
            <li>फास्ट फूड, प्रोसेस्ड फूड, और तला हुआ खाना कम करें।</li>
            <li>नशा, धूम्रपान, शराब, और सिगरेट से बचें।</li>
          </ul>
          <h3>गर्भधारण के लिए कोशिश करने के दौरान:</h3>
          <ul>
            <li>स्वस्थ और ताजे खाद्य पदार्थ खाएं।</li>
            <li>समान्य जीवनशैली अपनाएं और तनाव को कम करने के प्रयास करें।</li>
            <li>खाने के बाद 30 मिनट तक पानी पीने से बचें।</li>
            <li>ध्यान दें कि, गर्भधारण के लिए समय का चुनाव बहुत महत्वपूर्ण है।</li>
            <li>योग और ध्यान का अभ्यास करें ताकि शारीरिक और मानसिक स्थिति बेहतर रहे।</li>
          </ul>

          {/* Detailed Information for Conception */}
          <h3>गर्भधारण के लिए प्रयास करते समय इन बातों का ध्यान रखें:</h3>
          <p>
            गर्भधारण के लिए सबसे अच्छा समय ओव्यूलेशन के दौरान होता है। ओव्यूलेशन महिला के मासिक चक्र के मध्य में होता है, जो आमतौर पर माहवारी के 14 दिन बाद होता है।
            ओव्यूलेशन ट्रैकिंग के लिए आप अप्स या घर पर की जाने वाली टेस्ट किट्स का इस्तेमाल कर सकती हैं। यह आपको सही समय पर गर्भधारण के लिए प्रयास करने में मदद करेंगे।
          </p>

          <h3>संबंध बनाने के दौरान ध्यान रखें:</h3>
          <ul>
            <li>संबंध बनाने के लिए ओव्यूलेशन के समय का चुनाव करें, क्योंकि इस समय गर्भाधान की संभावना सबसे अधिक होती है।</li>
            <li>गर्भधारण में मदद के लिए, पुरुष को महिलाओं के साथ मिलकर पूरी तरह से शारीरिक संबंध बनाने चाहिए।</li>
            <li>संबंध बनाने के बाद, महिला को कम से कम 20 से 30 मिनट तक लेटी रहना चाहिए, ताकि शुक्राणु आसानी से गर्भाशय तक पहुंच सकें।</li>
            <li>गर्भधारण में सफलता के लिए, तनाव मुक्त वातावरण बनाएं। मानसिक शांति और शारीरिक आराम गर्भधारण की संभावना को बढ़ाते हैं।</li>
            <li>यह भी सलाह दी जाती है कि महिला और पुरुष दोनों नियमित रूप से इस प्रक्रिया में भाग लें।</li>
          </ul>

          <h3>क्या न करें:</h3>
          <ul>
            <li>संभावना को बढ़ाने के लिए ओव्यूलेशन के दौरान अत्यधिक तनाव से बचें।</li>
            <li>संभोग के बाद सीधे उठने या घबराहट करने से बचें, यह गर्भधारण की संभावना को कम कर सकता है।</li>
            <li>संवेदनशील समय पर अधिक शारीरिक थकावट से बचें।</li>
          </ul>
        </div>

        {/* Money-back Guarantee Note */}
        <div className="money-back-note">
          <h3>मनी बैक गारंटी के लिए जरूरी सूचना:</h3>
          <p>
            ऊपर दिए गए सभी कदमों का पालन करना मनी बैक गारंटी के लिए जरूरी है। अगर आप इन निर्देशों का पालन नहीं करेंगे, तो आप मनी बैक गारंटी के लिए पात्र नहीं हो सकते।
          </p>
          <p>
            कृपया ध्यान दें कि गर्भधारण एक प्राकृतिक प्रक्रिया है और इसमें समय लग सकता है। इसलिए, सही आहार, सही समय पर प्रयास, और मानसिक शांति की अहमियत है।
          </p>
        </div>
      </div>
    </>
  );
};

export default Info;
