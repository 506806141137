import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import FeaturesSection from "./components/FeaturesSection";
import FormSection from "./components/FormSection";
import FooterSection from "./components/FooterSection";
import TestimonialSection from "./components/TestimonialSection";
import GalleryComponent from "./components/GalleryComponent";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import Blog from "./components/Blog";
import BlogDetails from "./components/BlogDetails";
import ThankYou from "./components/ThankYou";
import Warning from "./components/Warning";
import Info from "./components/Info";
import Doctor from "./components/Doctor";
import MaleFemale from "./components/MaleFemale";
import SuccessCouples from "./components/SuccessCouples";


import "./App.css";

const App = () => {
  const [isRestricted, setIsRestricted] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Replace with your preferred geolocation API
    fetch("https://ipinfo.io/json?token=a6f2bf7eec9d7")
      .then((response) => response.json())
      .then((data) => {
        const state = data.region; // Example: "Gujarat"
        if (state === "Gujarat") {
          setIsRestricted(true);
        } else {
          setIsRestricted(false);
        }
      })
      .catch((error) => {
        console.error("Geolocation error:", error);
        setIsRestricted(false); // Default to allowing access if location detection fails
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isRestricted) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <h1>Access Restricted</h1>
        <p>Sorry, this website is not available</p>
      </div>
    );
  }

  return (
    <Router>
      {/* Global Meta Tags */}
      <Helmet>
        <title>shishuvardan - Ayurvedic Solution for Conceiving a Baby</title>
        <meta
          name="description"
          content="shishuvardan offers safe and effective Ayurvedic solutions for conceiving a baby naturally. Trusted by thousands of families."
        />
        <meta
          name="keywords"
          content="Ayurvedic baby conceive, natural fertility solution, shishuvardan Ayurveda, fertility Ayurveda, baby planning"
        />
        <meta name="author" content="shishuvardan Ayurveda" />
        <meta
          property="og:title"
          content="shishuvardan - Ayurvedic Solution for Conceiving a Baby"
        />
        <meta
          property="og:description"
          content="Discover safe and natural Ayurvedic treatments to conceive a baby with shishuvardan. Trusted by families for generations."
        />
        <meta property="og:image" content="/img/combo2.webp" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.shishuvardan.in" />
        <meta property="og:site_name" content="shishuvardan" />
        <link rel="icon" href="/img/f-icon.png" />
      </Helmet>

      <Header />
      
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
            
              <FeaturesSection />
             
              <Doctor />
              
              <MaleFemale />
              
              <SuccessCouples />
              
              <FormSection />
              
              <GalleryComponent />
              
              <TestimonialSection />
            </>
          }
        />
        <Route path="/form" element={<FormSection />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetails />} /> {/* Dynamic route */}
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/warning" element={<Warning />} />
        <Route path="/info" element={<Info />} />
      </Routes>

      <FooterSection />

      {/* Static Call Now Button */}
      <a
        href="tel:+917096695448"
        className="call-now-button"
        onClick={() => {
          // Google Analytics Event
          window.gtag("event", "click", {
            event_category: "Call Now",
            event_label: "Call Now Button Clicked",
            value: 1,
          });
        }}
      >
        <span className="phone-icon">📞</span>{" "}
        <span className="call-text">अभी फ़ोन करें</span>
      </a>

      {/* Link to navigate to Form Section */}
      <Link to="/form" className="order-now-button">
        <span className="order-icon">🛒</span> अभी ऑर्डर करें
      </Link>
    </Router>
  );
};

export default App;
