import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ThankYou.css";

/* global fbq */

const ThankYou = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const apiToken = "EAAPIrvK1U6QBOwoAuGeSZBrCCx6mB1W85wI4TgXSy8Ed8zex1ZAgP6KYSSKZCXAbA6onQIdX1S19uESrmuuzDsCcYk7ut4NHiABXemIvqT8hMIwZAtPLOWKwWupLImOdfIqfojoCAKJAlFLAPKLB5SyafPOzPTUtlQeZBy3XLZAMCfPZBbdkbET8ZAlYy9jH5ReWOQZDZD";

    // Track Lead Event using Facebook Pixel
    if (typeof fbq === "function") {
      const productData = {
        product_id: "12345",
        product_name: "Putra Vardan",
        product_price: 2490,
        currency: "INR",
      };

      fbq("track", "Lead", {
        value: productData.product_price,
        currency: productData.currency,
        content_ids: [productData.product_id],
        content_name: productData.product_name,
        content_type: "product",
        num_items: 1,
      });

      console.log("Lead event tracked with product details");
    } else {
      console.error("fbq function is not defined. Ensure Pixel script is loaded.");
    }

    // Send Event Data to Facebook API
    const sendEventToFacebook = async () => {
      const pixelId = "1290853132060924";
      const eventData = {
        data: [
          {
            event_name: "Lead",
            event_time: Math.floor(Date.now() / 1000),
            event_source_url: window.location.href,
            action_source: "website",
            custom_data: {
              value: 2490,
              currency: "INR",
            },
            user_data: {
              client_ip_address: "AUTO",
              client_user_agent: navigator.userAgent,
            },
          },
        ],
      };

      try {
        const response = await fetch(
          `https://graph.facebook.com/v13.0/${pixelId}/events?access_token=${apiToken}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(eventData),
          }
        );

        if (response.ok) {
          console.log("Lead event sent to Facebook API successfully");
        } else {
          console.error("Error sending Lead event to Facebook API:", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    sendEventToFacebook();
  }, []);

  const redirectToHome = () => {
    navigate("/");
  };

  return (
    <div className="thanks-pg">
      <div id="wrapper">
        <div className="container">
          <div className="order-block">
            <div className="text-holder">
              <img
                src="https://media.tenor.com/bm8Q6yAlsPsAAAAj/verified.gif"
                alt="Order Verified"
              />
              <h1>Thank You!</h1>
              <h2>आपका ऑर्डर स्वीकार हो गया है.</h2>
              <p>हमारा ऑपरेटर एड्रेस की पुष्टि करने के लिए आपसे संपर्क करेगे !!</p>
              <button className="btn" onClick={redirectToHome}>
                होम पेज पर जाएं
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
