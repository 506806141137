import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ProductSection.css';

const ProductSection = () => {
  const navigate = useNavigate(); // Hook for navigation

  const handleShopNow = () => {
    navigate('/form'); // Navigate to the form route
  };

  return (
    <section className="product-detail text-center">
      <h2>Our Product Price</h2>
      <div className="product-card">
        <div className="discount-tag">
          <span>50%</span> Off
        </div>
        <h3>1 Month Course</h3>
        <div className="product-images">
          <img src="img/combo.webp" alt="Combo" />
        </div>
        <p className="product-info">
          पुरुषों के लिए 30 कैप्सूल <br />
          महिलाओं के लिए 30 कैप्सूल
        </p>
        <div className="price-section">
          <span className="current-price">Rs. 2490</span>
          <span className="original-price">Rs. 4980</span>
          <p className="tax-info">incl. of all taxes</p>
        </div>
        <button onClick={handleShopNow} className="shop-now-btn">Shop Now</button>
      </div>
     
    </section>
  );
};

export default ProductSection;
