import React from "react";
import { useNavigate } from "react-router-dom";
import "./HeroSection.css";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleOrderNow = () => {
    navigate("/form");
  };

  return (
    <div className="main-container">
      <section className="hero-section">
        <div className="hero-wrapper">
          {/* Image Section */}
          <div className="hero-video"> {/* Reused class name for styling */}
            <img
              src="./img/C2.jpg" // Replace with your image file path
              alt="Shishuvardan"
              className="video-element" // Reused class name for styling
            />
          </div>
          {/* Content Section */}
          <div className="hero-content">
            <h1>शिशुवरदान, शिवलिंगी से बना हर्बल उत्पाद</h1>
            <p>बिना किसी दुष्प्रभाव के 100% आयुर्वेदिक</p>
            <p>मार्केट से डुप्लीकेट सस्ता प्रोडक्ट ना खरीदे</p>
            <button onClick={handleOrderNow} className="btn-order">
              अभी ऑर्डर करें
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
