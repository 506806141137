import React from "react";
import { useParams } from "react-router-dom";
import "./BlogDetails.css";

const BlogDetails = () => {
  const { id } = useParams();
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    fetch(`/blogs/blog${id}.json`)
      .then((res) => res.json())
      .then((data) => setPost(data))
      .catch((err) => console.error("Error loading blog:", err));
  }, [id]);

  if (!post) {
    return <h2>Blog post not found!</h2>;
  }

  return (
    <section className="blog-details-section">
      <div className="blog-details-container">
        <h1 className="blog-details-title">{post.title}</h1>
        <p className="blog-post-date">{post.date}</p>
        <img src={post.image} alt={post.title} className="blog-details-image" />
        <div className="blog-details-content">
          <p>{post.content}</p>
        </div>
      </div>
    </section>
  );
};

export default BlogDetails;
