import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Blog.css";

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    // Fetch all blogs dynamically
    const fetchBlogs = async () => {
      const blogs = [];
      for (let i = 1; i <= 10; i++) {
        const response = await fetch(`/blogs/blog${i}.json`);
        const data = await response.json();
        blogs.push(data);
      }
      setBlogPosts(blogs);
    };

    fetchBlogs();
  }, []);

  return (
    <section className="blog-section">
      <div className="blog-container">
        <h2>Our Latest Blog Posts</h2>
        <div className="blog-posts">
          {blogPosts.map((post) => (
            <div key={post.id} className="blog-post">
              <img src={post.image} alt={post.title} className="blog-post-image" />
              <div className="blog-post-content">
                <h3>{post.title}</h3>
                <p className="blog-post-date">{post.date}</p>
                <p>{post.content.split(" ").slice(0, 20).join(" ")}...</p>
                <Link to={`/blog/${post.id}`} className="read-more-link">
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
