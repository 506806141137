import React from "react";
import "./Warning.css";  // Importing the CSS file for styling

const Warning = () => {
  return (
    <div className="warning-container">
      <div className="warning-icon">
        ⚠️ {/* You can use an icon or replace it with an image */}
      </div>
      <div className="warning-message">
      <h2>सोनी द्वारा जनहित में जारी</h2>
        <h2>चेतावनी</h2>
        <p>
          <strong>फर्जी लीड डालने की कोशिश ना करें</strong>
        </p>
        <p>
          आपका आईपी एड्रेस और आपके डिवाइस की जानकारी हमारे पास आ चुकी है और
          आपके सर्वर को क्रैश कराने के लिए इतनी जानकारी काफी है।
        </p>
        <p>
          हमें मजबूर न करें, अन्यथा परिणाम भुगतने के लिए तैयार रहें।
        </p>
      </div>
    </div>
  );
};

export default Warning;
