import React from 'react';
import './doctor.css';
import AdBanner from './AdBanner';

<>
<AdBanner />
</>


const Doctor = () => {
  return (
    <div className="doctor-container">
      <div className="doctor-card">
        <img
          src="./img/d1.jpg" // Replace with the correct path to your image
          alt="अंजलि शर्मा"
          className="doctor-image"
        />
        <h3 className="doctor-name">अंजलि शर्मा - योग्य यूरोलॉजिस्ट</h3>
        <p className="doctor-description">
          पिछले 10 वर्षों के अनुभव के साथ, मैंने सैकड़ों जोड़ों को माता-पिता बनने का सपना साकार करने में मदद की है। 
          मेरा मानना है कि सही समय पर सही उपचार से हर समस्या का समाधान संभव है। ओव्यूलेशन और निषेचन के दौरान सही दिशा में कदम उठाने से गर्भधारण में आने वाली समस्याओं को दूर किया जा सकता है।
          कई बार जन्मजात कारणों या स्वास्थ्य से जुड़ी समस्याओं की वजह से बांझपन का सामना करना पड़ता है, लेकिन सही आयुर्वेदिक उपचार और समर्पित देखभाल से इसे ठीक किया जा सकता है।
        </p>
        <p className="doctor-description">
          मेरी विशेषता यह है कि मैं प्रत्येक मरीज के मामले को गहराई से समझती हूँ और उसके अनुसार व्यक्तिगत समाधान प्रदान करती हूँ। Shishu Vardan द्वारा निर्मित हमारी दवाई बांझपन को दूर करने में बहुत प्रभावी है। अगर आप अपने जीवन में संतान सुख की प्राप्ति चाहते हैं, तो आयुर्वेदिक समाधान आपके लिए सही विकल्प हो सकता है।
        </p>
        <div className="certification">
          <img
            src="./img/st1.png" // Replace with the correct path to your certification stamp
            alt="Certified"
            className="certification-stamp"
          />
        </div>
      </div>
    </div>
  );
};

export default Doctor;
