import React from 'react';
import './success_couples.css';

const SuccessCouples = () => {
  return (
    <div className="success-couples-container">
      <div className="success-card">
        <img
          src="./img/icon-3.png" // Replace with the correct path to the bottle image
          alt="Putraveda Bottles"
          className="bottle-image"
        />
        <h3 className="success-title">7000+ जोड़ों को मिली खुशियाँ</h3>
        <p className="success-paragraph">
          हमारी आयुर्वेदिक दवा ने 7000 से अधिक जोड़ों को माता-पिता बनने का सुख प्रदान किया है। यह दवा पूरी तरह से प्राकृतिक है और इसे शत-प्रतिशत आयुर्वेदिक तकनीकों से तैयार किया गया है। इसमें 18 प्रकार की दुर्लभ जड़ी-बूटियाँ जैसे शिवलिंगी, पुत्रजीवक, शतावरी, अश्वगंधा, और गोक्शुरा का उपयोग किया गया है। ये सभी घटक गर्भधारण की संभावना को प्राकृतिक रूप से बढ़ाने और प्रजनन क्षमता को मजबूत करने में अत्यधिक प्रभावी हैं।
        </p>
        <p className="success-paragraph">
          हमारी आयुर्वेदिक दवा का सबसे बड़ा लाभ यह है कि यह पूरी तरह से सुरक्षित है और इसका कोई भी साइड इफेक्ट नहीं होता। यह न केवल प्रजनन क्षमता को बढ़ाने में मदद करती है, बल्कि शरीर के हार्मोनल संतुलन को भी सुधारती है।
        </p>
        <p className="success-paragraph">
          यह दवा उन सभी के लिए वरदान है जो लंबे समय से संतान सुख की प्रतीक्षा कर रहे हैं। इसमें मौजूद शतावरी और अश्वगंधा जैसी जड़ी-बूटियाँ प्रजनन तंत्र को पुनर्जीवित करने और गर्भधारण की प्रक्रिया को सहज बनाने में मदद करती हैं। पुत्रजीवक और शिवलिंगी जैसी जड़ी-बूटियाँ अंडों और शुक्राणुओं की गुणवत्ता को बेहतर बनाती हैं, जिससे सफल गर्भधारण की संभावना बढ़ जाती है।
        </p>
        <p className="success-paragraph">
          हम यह दावा करते हैं कि हमारी दवा न केवल आपके स्वास्थ्य को बेहतर बनाएगी, बल्कि आपके जीवन में नई खुशियाँ लाएगी। अगर आप भी माता-पिता बनने का सपना देख रहे हैं, तो हमारी आयुर्वेदिक दवा को आज ही अपनाएँ।
        </p>
      </div>
    </div>
  );
};

export default SuccessCouples;
