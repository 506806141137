import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation for routing
import { isMobile, isTablet, isIOS, isAndroid } from "react-device-detect"; // Import only the required device detection functions
import "./FormSection.css";

const FormSection = () => {
  const [time, setTime] = useState(59 * 60); // Timer
  const [ipAddress, setIpAddress] = useState(""); // State for IP address
  const [deviceDetails, setDeviceDetails] = useState(""); // State for device details
  const [city, setCity] = useState(""); // State for city
  const navigate = useNavigate(); // For redirection
  const location = useLocation(); // Access the current route location

  // Scroll to top whenever the route changes with a slight delay
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0); // Force scroll to top after a slight delay
    }, 50); // Adjust the delay if needed (50 ms is just an example)
  }, [location]); // This hook will run whenever the location (route) changes

  // Timer functionality
  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Fetch IP address and City details
  useEffect(() => {
    // Fetch IP address
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setIpAddress(data.ip);

        // Fetch location (city) based on IP address
        fetch(`https://ipinfo.io/${data.ip}/json?token=a6f2bf7eec9d73`)
          .then((response) => response.json())
          .then((data) => {
            setCity(data.city || "Unknown City"); // Set city if available
          })
          .catch((error) => console.error("Error fetching city:", error));
      })
      .catch((error) => console.error("Error fetching IP address:", error));

    // Capture device details using react-device-detect
    let device = "Desktop"; // Default device type

    if (isMobile) device = "Mobile";
    if (isTablet) device = "Tablet";
    if (isIOS) device = "iPhone/iPad";
    if (isAndroid) device = "Android";

    setDeviceDetails(`${device}, ${city}`); // Combine device type and city
  }, [ipAddress, city]);

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    const currentTime = new Date().getTime(); // Current timestamp
    const storedData = JSON.parse(localStorage.getItem("formSubmissions")) || {}; // Retrieve stored data or initialize

    if (storedData[ipAddress]) {
      const timeDifference = currentTime - storedData[ipAddress]; // Time difference in milliseconds
      const hoursDifference = timeDifference / (1000 * 60 * 60); // Convert to hours

      if (hoursDifference < 24) {
        // If the same IP tries to submit within 24 hours, redirect to warning page
        navigate("/thankyou");
        return;
      }
    }

    // If 24 hours have passed or it's a new IP, allow form submission
    storedData[ipAddress] = currentTime; // Store the current timestamp for the IP address
    localStorage.setItem("formSubmissions", JSON.stringify(storedData)); // Save to localStorage

    // Submit the form (This is just an example; actual form submission logic depends on your setup)
    const form = document.getElementById("myForm");
    form.submit();
  };

  return (
    <section className="form-section">
      <h2 className="form-heading">ऑफर खत्म होने से पहले ऑर्डर करें</h2>
      <p className="subheading">
        पुत्रवरदान किट ऑर्डर करने के लिए नीचे दिया गया फॉर्म भरें।
      </p>
      <h3 className="offer-highlight">100% Money Back Guarantee</h3>
      <p className="limited-offer">Limited Time 50% Discount</p>

      <form
        action="https://crm.ezydevloping.site/forms/wtl/c92e1046a950ed149651513ae9f900be"
        method="post"
        encType="multipart/form-data"
        acceptCharset="utf-8"
        id="myForm"
        onSubmit={handleSubmit} // Custom submit handler
      >
        <input
          type="hidden"
          name="csrf_token_name"
          value="3e0b2affee9548e64f935c53795eee94"
        />
        <input
          type="hidden"
          name="key"
          value="c92e1046a950ed149651513ae9f900be"
        />
        <input
          type="hidden"
          name="websites"
          id="websites"
          className="form-control"
          value="https://putravardan.in/thankyou"
        />

        {/* Hidden input for IP address */}
        <input type="hidden" name="email" value={ipAddress} />

        {/* Hidden input for Device and City */}
        <input type="hidden" name="company" value={deviceDetails} />

        <label htmlFor="order-name">नाम</label>
        <input
          type="text"
          id="order-name"
          className="order-input"
          name="name"
          placeholder="अपना नाम दर्ज करे"
          required
        />

        <label htmlFor="order-number">नंबर</label>
        <input
          type="tel"
          id="order-number"
          className="order-input"
          name="phonenumber"
          placeholder="अपना फोन नंबर दर्ज करे"
          required
          maxLength="10"
          pattern="[0-9]{10}"
          title="Enter a valid 10-digit mobile number."
        />

        <label htmlFor="order-address">पता</label>
        <input
          type="text"
          id="order-address"
          className="order-input"
          name="address"
          placeholder="अपना पता दर्ज करे"
          required
        />

        <button type="submit" className="submit-button">
          ऑर्डर करे
        </button>
      </form>

      <div className="timer">
        <p>
          ऑफर समाप्त होने में समय: <strong>{`${Math.floor(time / 60)}:${
            time % 60
          }`}</strong>
        </p>
      </div>

      {/* Info button that redirects to /info */}
      <button
        type="button"
        className="info-button"
        onClick={() => navigate("/info")} // Redirect on button click
      >
        दवा को कैसे लेना है?
      </button>
    </section>
  );
};

export default FormSection;
